import { RemixBrowser } from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import { startTransition, StrictMode } from "react";
import { hydrateRoot } from "react-dom/client";

Sentry.init({
  dsn: window.ENV.SENTRY_DSN,
  // TODO: These errors are a known issue in Remix, see
  // https://github.com/remix-run/remix/issues/2570.
  ignoreErrors: [
    // Hydration failed because the initial UI does not match what was rendered on the server.
    "Minified React error #418",
    // There was an error while hydrating. Because the error happened outside of a Suspense boundary, the entire root will switch to client rendering.
    "Minified React error #423",
    // Text content does not match server-rendered HTML.
    "Minified React error #425",
  ],
  beforeSend: (event) => {
    // Ignore browsers which don’t support HTMLDialogElement.
    return typeof HTMLDialogElement === "undefined" ? null : event;
  },
  denyUrls: ["usercentrics.eu"],
});

function hydrate() {
  startTransition(() => {
    hydrateRoot(
      document,
      <StrictMode>
        <RemixBrowser />
      </StrictMode>
    );
  });
}

if (typeof requestIdleCallback === "function") {
  requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  setTimeout(hydrate, 1);
}
